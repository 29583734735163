import PropTypes from 'prop-types'
import Hero from '../../components/Hero/Hero';
import Header from '../../components/Header/Header';
import AboutUsHero from '../../components/AboutUsHero/AboutUsHero'
import WhoWeAre from '../../components/WhoWeAre/WhoWeAre'
import OurObjectives from '../../components/OurObjectives/OurObjectives'
import Footer from '../../components/Footer/Footer'
import productCategories from '../../pages/CategoriesProduct/productCategories';
import Timeline from '../../components/Timeline/Timeline';
import React, { useEffect, useState} from 'react'
import ContactButton from '../../components/ContactButton/ContactButton';
const AboutUs = props => {
  
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
      setScrollPosition(window.scrollY);
  };

  useEffect(() => {
      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);


  const HeaderContentEN = {
    listCategory: [
        {
            name: "Home",
            link: "/",
            listSubItem: []
        },
        {
            name: "About Us",
            link: "/aboutus",
            listSubItem: []
        },
        {
            name: "Contact Us",
            link: "/contactus",
            listSubItem: []
        },
        {
          name: "Products",
          listSubItem: productCategories.map(category => [category.title, `/product/${category.route.toLowerCase()}`])
      }
    ]
  }

  const [onClickNav, setOnClickNav] = useState(false);

  const toggleActive = () => {
      setOnClickNav(!onClickNav);
      document.body.style.overflow = onClickNav ? '' : 'hidden';
  }


  return (

    
    <div>
      <Header HeaderContent={HeaderContentEN} scrollPosition={scrollPosition} onClickNav={onClickNav} toggleActive={toggleActive}/>
      <AboutUsHero />
      <WhoWeAre />
      <Timeline />
      <OurObjectives />
      <ContactButton/>

      {/* <AboutUsHero />
      <WhoWeAre />
      <OurObjectives />
      <Footer /> */}
    </div>
  )
}

AboutUs.propTypes = {}

export default AboutUs